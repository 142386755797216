@media print {
	body * {
	  visibility: hidden;
	}
	#printContent,
	#printContent * {
	  visibility: visible;
	}
	#printContent {
	  border: none;
	  position: absolute;
	  left: 0;
	  top: 0;
	}

	#printContent .card-header {
		display: none;
	}

  //@page {
	//size: auto;   /* auto is the initial value */
	//margin: 0;  /* this affects the margin in the printer settings */
  //}
}
