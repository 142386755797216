.ant-select {
	width: 100%;
}

.ant-input-number {
	width: 100%;
}

.colorDescription {
	margin-bottom: 0;
	p {
		margin-bottom: 0;
	}
}

.sidebar {
	.nav,
	.sidebar-nav {
		width: 220px;
	}
	.nav-link:hover {
		color: #fff;
		background: #376a7b;
	}
}
@media (min-width: 992px) {
	.sidebar-fixed .sidebar {
		width: 220px;
	}
	html:not([dir="rtl"]) {
		.sidebar-lg-show.sidebar-fixed .main {
			margin-left: 220px;
		}
	}
}

.score-rows {
	margin-top: 2rem !important;
	h5,
	h4 {
		color: #8d8d8e;
	}
}
.app-header {
	.nav-item {
		margin-right: 1em;
		.nav-link {
			.nav-icon {
				margin-right: 0.5em;
			}
		}
	}
}
.row-with-background {
	background-color: #f0f3f5;
	padding: 0.5em;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	margin: 0.2em;
	margin-bottom: 2em;
}

.instructions-table {
	& th:nth-of-type(1) {
		background-color: lightgreen;
	}
	& th:nth-of-type(2) {
		background-color: green;
	}
	& th:nth-of-type(3) {
		background-color: yellow;
	}
	& th:nth-of-type(4) {
		background-color: orange;
	}
	& th:nth-of-type(5) {
		background-color: red;
	}
}

html,
body,
#root {
	height: 100%;
}

.loader {
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
/////AKDN Colors

.btn-outline-jamatColor {
	border-color: #009c98;
	color: white;
}
.btn-outline-akdn {
	border-color: #c8b385;
}

.btn-generic {
	@include button-variant(#c8b385, #c8b385);
	color: $white;
}
.btn-outline-generic {
	@include button-outline-variant(#c8b385);
}

.btn-jamat {
	@include button-variant(#009c98, #009c98);
}
.btn-outline-jamat {
	@include button-outline-variant(#009c98);
}

.help-content-container {
	.input-group-prepend {
		flex: 0.2;

		:last-child {
			flex: 1;
			text-align: left;
		}
	}
}
